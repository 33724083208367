import FluroContent from "./fluro-content";

class Awards extends FluroContent {
    constructor() {
        super("award");
    }
}

const _Awards = new Awards();
export default _Awards;
