import FluroContent from "./fluro-content";

class Medium extends FluroContent {
    constructor() {
        super("medium");
    }
}

const _Medium = new Medium();
export default _Medium;
