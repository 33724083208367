<template>
    <v-container class="d-flex justify-center form-container scrollable-container">
        <v-form>
            <v-row class="form-row">
                <v-col cols="12">
                    <v-row dense>
                        <v-col v-if="formStep != finalPage" cols="12">
                            <h2 v-if="!edit" class="text-center">Create Competition</h2>
                            <h2 v-else class="text-center">Edit Competition</h2>
                        </v-col>
                    </v-row>

                    <v-row dense>
                        <template v-if="formStep === finalPage - 5">
                            <h3>Competition Name & Thumbnail:</h3>
                            <v-col cols="12">
                                <v-text-field
                                    v-model="model.title"
                                    :disabled="loading"
                                    type="text"
                                    class="field"
                                    dense
                                    filled
                                    solo
                                    flat
                                    label="Competition Name"
                                    placeholder="Enter the name of the competition"
                                    required
                                    :rules="[rules.required]"
                                    hint="The name of your competition. This will be disaplyed to entrants."
                                    persistent-hint
                                ></v-text-field>
                            </v-col>

                            <v-col cols="12">
                                <image-upload
                                    :readonly="false"
                                    full-width
                                    :sizeRestriction="true"
                                    :create="true"
                                    v-model="model.data.image"
                                ></image-upload>
                            </v-col>
                            <v-col class="my-4" cols="12">
                                <h3>Type:</h3>
                            </v-col>

                            <v-col cols="6">
                                <v-select
                                    v-model="model.data.judgingType"
                                    :items="judgingTypes"
                                    label="Judging Type"
                                    item-text="text"
                                    item-value="value"
                                    required
                                    hint="Online - Judges will give scores by logging into this portal.
                                    Live Event - Judges will give feedback and scores at a live event.
                                    "
                                    persistent-hint
                                ></v-select>
                            </v-col>
                            <v-col cols="6">
                                <v-select
                                    v-model="model.data.type"
                                    :items="types"
                                    label="Competition Type"
                                    item-text="text"
                                    item-value="value"
                                    required
                                    hint="Will entrant be required to send their images to a phyical address?"
                                    persistent-hint
                                ></v-select>
                            </v-col>
                            <v-col cols="6">
                                <v-select
                                    v-model="model.data.media"
                                    :items="media"
                                    label="Media Type"
                                    item-text="text"
                                    item-value="value"
                                    required
                                    hint="Will this be images or videos?"
                                    persistent-hint
                                ></v-select>
                            </v-col>
                            <v-col cols="6">
                                <v-checkbox
                                    v-model="model.data.cameraUsed"
                                    label="Camera Used Question"
                                    hint="Add a question asking the entrant what camera they used?"
                                    persistent-hint
                                ></v-checkbox>
                            </v-col>
                        </template>
                        <template v-if="formStep === finalPage - 4">
                            <v-col class="my-4" cols="12">
                                <h3>Price:</h3>
                            </v-col>

                            <v-col cols="6">
                                <v-text-field
                                    :prefix="`$${$app.currency}`"
                                    v-model="feedbackPrice"
                                    :disabled="loading"
                                    type="number"
                                    class="field"
                                    dense
                                    filled
                                    solo
                                    flat
                                    label="Feedback Price"
                                    placeholder="Enter a feedback price."
                                    required
                                    hint="The cost of feedback per entry."
                                    persistent-hint
                                ></v-text-field>
                            </v-col>

                            <v-col v-if="model.data.type == 'print'" cols="6">
                                <v-text-field
                                    :prefix="`$${$app.currency}`"
                                    v-model="returnPrice"
                                    :disabled="loading"
                                    type="number"
                                    class="field"
                                    dense
                                    filled
                                    solo
                                    flat
                                    label="Return Price"
                                    placeholder="Enter a return price."
                                    required
                                    :rules="[rules.required]"
                                    hint="If the entrant required the print to be returened the price of that process."
                                    persistent-hint
                                ></v-text-field>
                            </v-col>
                            <!-- Categories -->
                            <template v-if="model.data.type == 'print'">
                                <v-col class="my-4" cols="12">
                                    <h3>Printing Medium:</h3>
                                </v-col>

                                <v-col cols="10">
                                    <v-select
                                        v-model="selectedPrintingMediums"
                                        :items="printingMediums"
                                        label="Medium"
                                        hint="What printing mediums can an artist choose?"
                                        persistent-hint
                                        multiple
                                    >
                                    </v-select>
                                </v-col>
                                <v-col cols="2" class="mx-auto my-auto">
                                    <v-btn x-small @click="addArticle('Medium')" color="primary"
                                        ><v-icon>mdi-plus</v-icon></v-btn
                                    >
                                </v-col>
                            </template>

                            <v-col class="my-4" cols="12">
                                <h3>Categories:</h3>
                            </v-col>

                            <v-col cols="10">
                                <v-select
                                    v-model="selectedCategories"
                                    :items="categories"
                                    label="Categories"
                                    hint="What categories does your competition have?"
                                    persistent-hint
                                    multiple
                                >
                                    <!-- <template #item="{ item }">
                                        <v-list-item-avatar>
                                            <v-img :src="item.image" width="32" height="32"></v-img>
                                        </v-list-item-avatar>
                                        <v-list-item-content>
                                            <v-list-item-title>{{ item.text }}</v-list-item-title>
                                        </v-list-item-content>
                                    </template> -->
                                </v-select>
                            </v-col>
                            <v-col cols="2" class="mx-auto my-auto">
                                <v-btn x-small @click="addArticle('Category')" color="primary"
                                    ><v-icon>mdi-plus</v-icon></v-btn
                                >
                            </v-col>
                            <!-- <v-col>
                                <v-row>
                                    <v-col v-for="(category, index) in selectedCategories" :key="index" cols="4">
                                        <template v-if="category">
                                            <v-chip class="ma-2 px-2 black--text" color="primary">
                                                {{ populateText(category, "category") }}
                                                <v-icon small class="ma-0" @click="removeSelected(index, 'category')"
                                                    >mdi-close</v-icon
                                                >
                                            </v-chip>
                                        </template>
                                    </v-col>
                                </v-row>
                            </v-col> -->
                            <!-- Misc Variables -->
                            <v-col class="my-4" cols="12">
                                <h3>Miscellaneous:</h3>
                            </v-col>
                            <v-col cols="6">
                                <v-text-field
                                    v-model="model.data.maxFeedbackCount"
                                    :disabled="loading"
                                    type="number"
                                    class="field"
                                    dense
                                    filled
                                    solo
                                    flat
                                    label="Entry Feedback Limit"
                                    required
                                    hint="How many judges can leave feeback before the entry gets locked?"
                                    persistent-hint
                                ></v-text-field>
                            </v-col>
                            <v-col cols="6">
                                <v-text-field
                                    v-model="model.data.maxScoreCount"
                                    :disabled="loading"
                                    type="number"
                                    class="field"
                                    dense
                                    filled
                                    solo
                                    flat
                                    label="Entry Score Limit"
                                    required
                                    hint="How many judges can leave a score before the entry gets locked?"
                                    persistent-hint
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12">
                                <v-select
                                    v-model="model.data.formula"
                                    :items="formulae"
                                    hint="The formula your competitions final score will be calculated with. Please contact us if you need a custom formula."
                                    persistent-hint
                                    label="Judging Formula"
                                >
                                    <template #item="{ item }">
                                        <v-list-item-content>
                                            <v-list-item-title>{{ item.text }}</v-list-item-title>
                                        </v-list-item-content>
                                    </template>
                                </v-select>
                            </v-col>
                        </template>
                        <template v-if="formStep === finalPage - 3">
                            <v-col class="my-4" cols="12">
                                <h3>Purchasing Start/End Date:</h3>
                            </v-col>

                            <!-- Purchase -->
                            <v-col cols="6">
                                <date-picker
                                    key="PurchaseStart"
                                    :range="false"
                                    class="field"
                                    v-model="model.data.purchase.startDate"
                                    :selected="optionsPurchaseStart"
                                    hide-details
                                ></date-picker>
                                <p class="hint-text">Purchase start date</p>
                            </v-col>

                            <v-col cols="6">
                                <date-picker
                                    key="PurchaseEnd"
                                    :range="false"
                                    class="field"
                                    v-model="model.data.purchase.endDate"
                                    :selected="optionsPurchaseEnd"
                                    hide-details
                                ></date-picker>
                                <p class="hint-text">Purchase end date</p>
                            </v-col>

                            <!-- Submission -->
                            <v-col class="my-4" cols="12">
                                <h3>Submission Start/End Date:</h3>
                            </v-col>

                            <v-col cols="6">
                                <date-picker
                                    key="SubStart"
                                    :range="false"
                                    class="field"
                                    v-model="model.data.submission.startDate"
                                    :selected="optionsSubStart"
                                    hide-details
                                ></date-picker>
                                <p class="hint-text">Submission start date</p>
                            </v-col>

                            <v-col cols="6">
                                <date-picker
                                    key="SubEnd"
                                    :range="false"
                                    class="field"
                                    v-model="model.data.submission.endDate"
                                    :selected="optionsSubEnd"
                                    hide-details
                                ></date-picker>
                                <p class="hint-text">Submission end date</p>
                            </v-col>
                            <!-- Judging -->
                            <v-col class="my-4" cols="12">
                                <h3>Judging Start/End Date:</h3>
                            </v-col>

                            <v-col cols="6">
                                <date-picker
                                    key="JudgingStart"
                                    :range="false"
                                    class="field"
                                    v-model="model.data.judging.startDate"
                                    :selected="optionsJudgingStart"
                                    hide-details
                                ></date-picker>
                                <p class="hint-text">Judging start date. (Should be after the end of submissions)</p>
                            </v-col>
                            <v-col cols="6">
                                <date-picker
                                    key="JudgingEnd"
                                    :range="false"
                                    class="field"
                                    v-model="model.data.judging.endDate"
                                    :selected="optionsJudgingEnd"
                                    hide-details
                                ></date-picker>
                                <p class="hint-text">Judging end date.</p>
                            </v-col>
                        </template>
                        <template v-if="formStep === finalPage - 2">
                            <!-- Bundles -- Select / create? -->
                            <v-col class="my-4" cols="12">
                                <h3>Bundles:</h3>
                            </v-col>
                            <v-checkbox
                                v-model="model.data.freeEntry"
                                label="Enable Free Entries"
                                hint="This will disable payments and bundles and allow entrants to create entries for FREE"
                                persistent-hint
                            ></v-checkbox>
                            <v-col cols="10">
                                <v-select
                                    v-if="!model.data.freeEntry"
                                    v-model="selectedBundles"
                                    :items="bundles"
                                    hint="Select all relevant entry bundles. Bundles have an expiry date."
                                    persistent-hint
                                    label="Bundles"
                                    multiple
                                >
                                    <template #item="{ item }">
                                        <v-list-item-content>
                                            <v-list-item-title>{{ item.text }}</v-list-item-title>
                                            <small
                                                >{{ item.dateRange.startDate.toLocaleDateString() }} -
                                                {{ item.dateRange.endDate.toLocaleDateString() }}
                                            </small>
                                        </v-list-item-content>
                                    </template>
                                </v-select>
                            </v-col>
                            <v-col cols="2" class="mx-auto my-auto" v-if="!model.data.freeEntry">
                                <v-btn x-small @click="addArticle('Entry Bundle')" color="primary"
                                    ><v-icon>mdi-plus</v-icon></v-btn
                                >
                            </v-col>
                            <!-- <v-col>
                                <v-row>
                                    <v-col v-for="(bundle, index) in selectedBundles" :key="index" cols="6">
                                        <template v-if="bundle">
                                            <v-chip class="ma-2 px-2 black--text" color="primary">
                                                {{ populateText(bundle, "bundle") }}
                                                <v-icon small class="ma-0" @click="removeSelected(index, 'bundle')"
                                                    >mdi-close</v-icon
                                                >
                                            </v-chip>
                                        </template>
                                    </v-col>
                                </v-row>
                            </v-col> -->
                            <!-- //////////////////////// -->
                            <!-- All of these are handled by the Edit Stage, no point re-doing it -->
                            <!-- Categories -- Select & Create -->

                            <!-- Category Judges --Select from list -->

                            <!-- Feedback Judges -->

                            <!-- //////////////////////// -->
                            <!-- Awards Select & Create -->
                            <v-col class="my-4" cols="12">
                                <h3>Awards:</h3>
                            </v-col>

                            <v-col cols="10">
                                <v-select
                                    v-model="selectedAwards"
                                    hint="What awards are associated with your competition?"
                                    persistent-hint
                                    :items="awards"
                                    label="Awards"
                                    multiple
                                >
                                    <template #item="{ item }">
                                        <v-list-item-avatar>
                                            <v-img :src="item.image" width="32" height="32"></v-img>
                                        </v-list-item-avatar>
                                        <v-list-item-content>
                                            <v-list-item-title>{{ item.text }}</v-list-item-title>
                                        </v-list-item-content>
                                    </template>
                                </v-select>
                            </v-col>
                            <v-col cols="2" class="mx-auto my-auto">
                                <v-btn x-small @click="addArticle('Award')" color="primary"
                                    ><v-icon>mdi-plus</v-icon></v-btn
                                >
                            </v-col>
                            <v-col>
                                <v-row>
                                    <v-col v-for="(award, index) in selectedAwards" :key="index" cols="4">
                                        <template v-if="award">
                                            <div class="ma-0 px-0 black--text" color="primary" label>
                                                <v-avatar>
                                                    <v-img :src="populateImage(award, 'award')" />
                                                </v-avatar>
                                                {{ populateText(award, "award") }}
                                                <v-icon small class="ma-0" @click="removeSelected(index, 'award')"
                                                    >mdi-close</v-icon
                                                >
                                            </div>
                                        </template>
                                    </v-col>
                                </v-row>
                            </v-col>
                            <v-col class="my-4" cols="12">
                                <h3>Max Entires:</h3>
                            </v-col>
                            <v-col cols="12">
                                <v-text-field
                                    v-model="model.data.maxEntries"
                                    :disabled="loading"
                                    type="number"
                                    class="field"
                                    dense
                                    filled
                                    solo
                                    flat
                                    label="Max Entries "
                                    placeholder="Max Entries"
                                    required
                                    hint="Enter the limit for entries. This takes into account all entries. Including those received for free."
                                    persistent-hint
                                ></v-text-field>
                            </v-col>
                            <v-col class="my-4" cols="12">
                                <h3>Description:</h3>
                            </v-col>

                            <v-col cols="12">
                                <v-textarea
                                    v-model="model.body"
                                    :disabled="loading"
                                    type="text"
                                    class="field"
                                    dense
                                    filled
                                    solo
                                    flat
                                    label="Description"
                                    placeholder="Enter a description for your competition"
                                    required
                                    :rules="[rules.required]"
                                    hint="A detailed description of your competition."
                                    persistent-hint
                                ></v-textarea>
                            </v-col>
                        </template>
                    </v-row>
                </v-col>
                <template v-if="formStep === finalPage - 1">
                    <v-col class="my-4" cols="12">
                        <h3>Add Questions To Entries:</h3>
                    </v-col>

                    <ExtraQuestion
                        :key="index"
                        :categories="categories"
                        v-for="(question, index) in questions"
                        v-model="questions[index]"
                    >
                        <template v-slot:default>
                            <v-btn x-small class="pa-0 ma-0" color="error" @click="removeQuestion(index)">
                                <v-icon size="20">mdi-minus</v-icon>
                            </v-btn>
                        </template>
                    </ExtraQuestion>

                    <v-col cols="12">
                        <v-row class="action-contianer pa-0 ma-0">
                            <v-btn small color="primary" @click="addQuestion(null)">
                                <v-icon>mdi-plus</v-icon> Add Question
                            </v-btn>
                        </v-row>
                    </v-col>
                </template>
                <template v-if="formStep === finalPage">
                    <v-cols class="mx-auto" cols="12">
                        <v-progress-circular size="100" color="primary" indeterminate></v-progress-circular>
                    </v-cols>
                </template>
                <v-row class="action-contianer" v-if="formStep != finalPage">
                    <v-btn color="primary" v-if="formStep != 0" @click="back">
                        <p class="my-auto">Back</p>
                    </v-btn>
                    <v-btn color="primary" @click="submit" :disabled="isDisabled">
                        <p class="my-auto" v-if="formStep != finalPage - 1">Next</p>
                        <p class="my-auto" v-else>Submit</p>
                    </v-btn>
                </v-row>
            </v-row>
        </v-form>

        <ArticleCreateDialog
            v-model="createArticleObject"
            ref="articleCreateDialog"
            :categoryType="model.data.type"
            :type="type"
            @confirm="confirmArticle"
        ></ArticleCreateDialog>
        <process-dialog ref="processDialog" :title="processTitle"></process-dialog>
    </v-container>
</template>

<script>
import ExtraQuestion from "../../../../components/form-field/extra-question.vue";
import ButtonsField from "@/components/form-field/buttons-field";
import DatePicker from "../../../../components/form-field/date-picker.vue";
import EntryTile from "../../../../components/global/entry-tile.vue";
import Awards from "@/modules/app-module/awards.js";
import Bundle from "@/modules/app-module/bundle.js";
import Reaction from "@/modules/app-module/reaction.js";
import Category from "@/modules/app-module/category.js";
import Medium from "@/modules/app-module/medium.js";
import Competition from "@/modules/app-module/competition.js";
import ImageUpload from "@/components/global/image-upload.vue";
import ArticleCreateDialog from "../../../../components/dialogs/article-create-dialog.vue";
import ProcessDialog from "@/components/dialogs/process-dialog.vue";
export default {
    name: "competition-create",

    components: {
        ButtonsField,
        EntryTile,
        DatePicker,
        ImageUpload,
        ExtraQuestion,
        ArticleCreateDialog,
        ProcessDialog,
    },

    data() {
        return {
            editId: null,
            edit: false,
            processTitle: "",
            createArticleObject: null,
            type: null,
            questions: [], // Initialize with one empty input
            loading: false,
            formStep: 0, //Must be 0
            optionsPurchaseStart: {
                minimum: 0,
                maximum: 1,
            },
            optionsPurchaseEnd: {
                minimum: 0,
                maximum: 1,
            },
            optionsSubStart: {
                minimum: 0,
                maximum: 1,
            },
            optionsSubEnd: {
                minimum: 0,
                maximum: 1,
            },
            optionsJudgingStart: {
                minimum: 0,
                maximum: 1,
            },
            optionsJudgingEnd: {
                minimum: 0,
                maximum: 1,
            },
            feedbackPrice: null,
            returnPrice: null,
            model: {
                title: null, //String
                realms: [],
                data: {
                    cameraUsed: true,
                    media: null,
                    questions: [],
                    maxFeedbackCount: null,
                    maxScoreCount: null,
                    judgingType: null, //Select
                    type: null, //Select
                    feedbackPrice: null, //Number
                    maxEntries: null, //number
                    image: null, //Image Upload
                    purchase: {
                        startDate: null, //Date
                        endDate: null, //Date
                        closed: false,
                    },
                    submission: {
                        startDate: null, //Date
                        endDate: null, //Date
                        closed: false,
                    },
                    judging: {
                        startDate: null, //Date
                        endDate: null, //Date
                        closed: false,
                    },
                    bundles: [], //Fluro Ref
                    categories: [], //Fluro Ref
                    categoryJudges: [], //Fluro Ref
                    feebackJudges: [], //Fluro Ref
                    awards: [], //Fluro Ref
                    returnPrice: null, //Number
                    formula: null,
                },
                body: null, //String
            },
            extraQuestions: [],
            judgingTypes: [
                { text: "Online", value: "online" },
                { text: "Live Event", value: "live" },
            ],
            types: [
                { text: "Digital", value: "digital" },
                { text: "Print", value: "print" },
            ],
            media: [
                { text: "Image", value: "image" },
                { text: "Video", value: "video" },
            ],
            finalPage: 5, //Final page of form creation
            formulae: [],
            categories: [],
            selectedCategories: [],
            awards: [],
            bundles: [],
            selectedBundles: [],
            selectedAwards: [],
            selectedPrintingMediums: [],
            printingMediums: [],
            rules: {
                required: (value) => !!value || "This field is required",
                minLength3: (value) => value.length >= 3 || "Min 3 characters",
                minLength4: (value) => value.length >= 4 || "Min 4 characters",
                minLength6: (value) => value.length >= 6 || "Min 6 characters",
                isMatch: (value) => value === this.confirmPassword,
                email: (value) => {
                    const pattern =
                        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                    return pattern.test(value) || "Invalid e-mail";
                },
            },
            success: null,
        };
    },
    computed: {
        isDisabled() {
            // Page 1
            if (this.formStep == 0) {
                if (!this.model.title) return true;
                if (!this.model.data.type) return true;
                if (!this.model.data.image) return true;
                if (!this.model.data.media) return true;
                if (!this.model.data.judgingType) return true;
            }

            //Page 2
            if (this.formStep == 1) {
                if (this.model.data.feedbackPrice < 0 || !this.model.data.feedbackPrice == null) return true;
                if (!this.model.data.returnPrice && this.model.data.type == "print") return true;
                if (!this.model.data.categories) return true;
                if (!this.model.data.formula) return true;
                if (!this.model.data.maxFeedbackCount) return true;
                if (!this.model.data.maxScoreCount) return true;
            }
            // //Page 3
            if (this.formStep == 2) {
                if (!this.model.data.purchase.startDate) return true;
                if (!this.model.data.purchase.endDate) return true;
                if (!this.model.data.submission.startDate) return true;
                if (!this.model.data.submission.endDate) return true;
                if (!this.model.data.judging.startDate) return true;
                if (!this.model.data.judging.endDate) return true;
            }
            // console.log(!this.model.data.bundles, !this.model.data.bundles, !this.model.data.body);
            // //Page 4
            if (this.formStep == 3) {
                console.log(!this.model.data.bundles, !this.model.data.bundles, !this.model.data.body);
                if (
                    (!this.selectedBundles.length > 0 && !this.model.data.freeEntry) ||
                    (this.selectedBundles.length == 0 && !this.model.data.freeEntry)
                )
                    return true;
                if (!this.selectedAwards.length > 0) return true;
                if (!this.model.data.maxEntries > 0) return true;
                if (!this.model.body) return true;
            }

            //Page 5
            //Nothing here is required so submit is okay
            if (this.formStep == 4 && this.questions.length > 0) {
                const valid = this.questions.some((p) => {
                    console.log("LIMIT", !p.limit, p.limit);
                    console.log("CAT", !p.category.length, p.category);
                    console.log("QUES", !p.question, p.question);
                    if (!p.limit) return true;

                    if (!p.category.length > 0) return true;

                    if (!p.question) return true;
                });
                console.log(valid);
                return valid;
            }
            return false;
        },
    },
    methods: {
        addArticle(type) {
            this.type = type;
            this.$refs.articleCreateDialog.open();
            this.processTitle = `Adding ${type}`;
        },
        getContentType(type) {
            switch (type) {
                case "Category":
                    return "category";

                case "Entry Bundle":
                    return "bundle";

                case "Award":
                    return "award";
                case "Medium":
                    return "medium";
                default:
                    break;
            }
        },
        async updateCreatedList(type) {
            switch (type) {
                case "Category":
                    await this.getCategories();
                    return "category";

                case "Entry Bundle":
                    await this.getBundles();
                    return "bundle";

                case "Award":
                    await this.getAwards();
                    return "award";

                case "Medium":
                    await this.getPrintingMediums();
                    return "medium";

                default:
                    return "meidum";
            }
        },
        async confirmArticle(payload, type, _id) {
            this.$refs.articleCreateDialog.close();
            this.$refs.processDialog.open(`Four`); //Has 4 steps so 4, based on length

            this.$refs.processDialog.increment();
            const contentType = this.getContentType(type);
            this.$refs.processDialog.increment();

            let created = null;
            //Due to an award being an image it is already uploaded, just need to modify its definition
            if (type == "Award") {
                created = await this.$fluro.api.put(`/content/image/${_id}`, payload).then(({ data }) => data);
            } else {
                created = await this.$fluro.api.post(`/content/${contentType}`, payload).then(({ data }) => data);
            }

            this.$refs.processDialog.increment();
            await this.updateCreatedList(type);
            this.$refs.processDialog.increment();

            // Buffer to let 100% sit for a second
            await new Promise((resolve) => setTimeout(resolve, 1000));

            this.$refs.processDialog.close();

            await this.init();
        },
        removeQuestion(index) {
            this.questions.splice(index, 1);
        },
        addQuestion(load) {
            //Loads questions in from model
            console.log(" Loading Questions", load);
            if (load) {
                this.questions = [...load];
            } else {
                const obj = { limit: 200, question: "Enter question here!", category: [] };
                this.questions = [...this.questions, obj]; // Add a new empty input to the array
            }
        },
        async submit() {
            this.loading = true;
            //add addition information
            this.formStep++;
            if (this.formStep >= this.finalPage) {
                if (this.model.data.feedbackPrice <= 0 || !this.model.data.feedbackPrice) {
                    this.model.data.feedbackPrice = 0;
                }

                this.model.data.questions = this.questions;
                this.model.realms = [this.$app.liveRealm];
                this.model.data.awards = this.selectedAwards.filter((item) => {
                    if (item) {
                        return item;
                    }
                });
                this.model.data.bundles = this.selectedBundles;
                this.model.data.categories = this.selectedCategories;
                this.model.data.printingMediums = this.selectedPrintingMediums;
                let response = null;
                if (this.edit) {
                    console.log("EDIT");
                    response = await Competition.update(this.editId, this.model);
                } else {
                    console.log("CREATE");
                    response = await Competition.create(this.model);
                }

                if (response.status == 200) {
                    this.success == true;
                    this.$router.push({ path: `/admin/competitions/${response.data._id}` });
                }
            }
            this.loading = false;
        },
        back() {
            if (this.formStep > 0) {
                this.formStep--;
            }
        },
        populateText(item, type) {
            //Get the value
            if (type == "award") {
                let award = this.awards.find((ele) => ele.value == item);
                award = award.text.split(".")[0];
                return award;
            } else if (type == "bundle") {
                let bundle = this.bundles.find((ele) => ele.value == item);
                bundle = bundle.text;
                return bundle;
            } else if (type == "category") {
                let categories = this.categories.find((ele) => ele.value == item);
                categories = categories.text;
                return categories;
            }
        },
        populateImage(item, type) {
            //Get the image
            if (type == "category") {
                //get image ID
                item = this.categories.find((ele) => ele.value == item);
                item = item.image;
            }
            const image = this.$fluro.asset.imageUrl(item);
            return image;
        },
        removeSelected(index, type) {
            if (type == "award") {
                this.model.data.awards.splice(index, 1);
                this.$set(this.selectedAwards, index, false);
            } else if (type == "bundle") {
                this.model.data.bundles.splice(index, 1);
                this.$set(this.selectedBundles, index, false);
            } else if (type == "category") {
                this.model.data.categories.splice(index, 1);
                this.$set(this.selectedCategories, index, false);
            } else if (type == "medium") {
                this.model.data.printingMediums.splice(index, 1);
                this.$set(this.selectedPrintingMediums, index, false);
            }
        },

        async init() {
            await this.$fluro.resetCache();
            //Get info to edit
            if (this.$route.query.id) {
                this.edit = true;
                this.editId = this.$route.query.id;
                const response = await Competition.get(this.editId).then(({ data }) => data);
                console.log("LOADING COMP FOR EDIT", response);
                this.model = response;
                this.feedbackPrice = response.data.feedbackPrice / 100;
                this.selectedBundles = response.data.bundles.map((item) => item._id);
                this.selectedCategories = response.data.categories.map((item) => item._id);
                this.selectedPrintingMediums = response?.data?.printingMediums?.map((item) => item._id);
                this.selectedAwards = response.data.awards.map((item) => {
                    if (item) {
                        return item._id;
                    }
                });
                this.model.data.formula = response.data.formula._id;

                this.addQuestion(response.data.questions);
            }
            await this.getAwards();
            await this.getBundles();
            await this.getCategories();
            await this.getFormulae();
            await this.getPrintingMediums();
        },
        async getFormulae() {
            this.formulae = [];
            const response = await Reaction.list().then(({ data }) => data);
            console.log("Reaction", response);
            // const filtered = response.filter((item) =>
            //     item.realms.some((realm) => realm._id === this.$app.publicRealm)
            // );
            console.log(response);
            response.forEach((element) => {
                const text = `${element.title}`;
                const value = element._id;
                const item = { text: text, value: value };
                this.formulae.push(item);
            });
        },
        async getCategories() {
            this.categories = [];
            const response = await Category.list({
                params: { status: "active" },
            }).then(({ data }) => data);
            console.log("GOT CATEGORIES", response);
            const filtered = response.filter((item) => item.realms.some((realm) => realm._id === this.$app.liveRealm));
            console.log("FILTERED CAT", filtered);
            filtered.forEach((element) => {
                const text = `${element.title} | Type: ${element.data.type}`;
                const value = element._id;
                const image = this.populateImage(element.data.image._id);
                const item = { text: text, value: value, image: image };
                this.categories.push(item);
            });
        },
        async getPrintingMediums() {
            this.printingMediums = [];
            const response = await Medium.list({
                params: { status: "active" },
            }).then(({ data }) => data);
            console.log("GOT MEDIUMS", response);
            const filtered = response.filter((item) => item.realms.some((realm) => realm._id === this.$app.liveRealm));
            console.log("FILTERED MEDIUM", filtered);
            filtered.forEach((element) => {
                const text = `${element.title} | Price: $${(element?.data?.price / 100).toFixed(2)}`;
                const value = element._id;
                const item = { text: text, value: value };
                this.printingMediums.push(item);
            });
        },
        async getBundles() {
            this.bundles = [];
            const response = await Bundle.list({
                params: { status: "active" },
            }).then(({ data }) => data);
            let filtered = response.filter((item) => item.realms.some((realm) => realm._id === this.$app.liveRealm));
            //Filter by Date

            filtered = filtered.filter((option) => {
                //Filter by date
                if (option.definition == "bundle") {
                    const today = new Date();
                    today.setHours(0, 0, 0, 0);

                    const bundleStartDate = new Date(option.data.startDate);
                    bundleStartDate.setHours(0, 0, 0, 0);
                    const bundleEndDate = new Date(option.data.endDate);
                    bundleEndDate.setHours(0, 0, 0, 0);
                    // Check if bundle's start date is same or after today
                    // and bundle's end date is strictly in the future

                    return bundleEndDate.getTime() > today.getTime();
                }
            });

            filtered.forEach((element) => {
                const text = `${element.title} | Entries: ${element.data.entries} | Price: $${
                    element.data.price / 100
                }`;
                const dateRange = {
                    startDate: new Date(element.data.startDate),
                    endDate: new Date(element.data.endDate),
                };
                const value = element._id;
                const item = { text: text, value: value, dateRange: dateRange };
                this.bundles.push(item);
            });
        },
        async getAwards() {
            this.awards = [];
            const response = await Awards.list({
                params: { status: "active" },
            }).then(({ data }) => data);
            const filtered = response.filter((item) => item.realms.some((realm) => realm._id === this.$app.liveRealm));
            filtered.forEach((element) => {
                const text = element.title;
                const value = element._id;
                const image = this.populateImage(element._id);
                const item = { text: text, value: value, image: image };
                this.awards.push(item);
            });
        },
    },

    watch: {
        feedbackPrice(v) {
            this.model.data.feedbackPrice = v * 100;
        },
        returnPrice(v) {
            this.model.data.returnPrice = v * 100;
        },
        questions(v) {
            console.log(v);
        },
    },
    async mounted() {
        await this.init();
    },
};
</script>
<style scoped>
.scrollable-container {
    overflow-y: auto;
    max-height: 100%; /* Adjust this value according to your needs */
}
.form-container {
    padding-top: 24px;
    padding-bottom: 24px;
}
.action-contianer {
    display: flex;
    margin-top: 10px;
    justify-content: center;
    align-content: center;
    column-gap: 5px;
    flex-wrap: wrap;
}

.form-row {
    max-width: 700px;
    margin: 0 auto;
}

.hint-text {
    font-size: 12px;
    color: grey;
}
</style>
