<template>
    <v-row>
        <v-col>
            <slot></slot>
        </v-col>
        <v-col cols="12">
            <v-text-field
                v-model="internalData.question"
                type="text"
                class="field"
                dense
                filled
                solo
                flat
                label="Enter Question Here"
                placeholder="Enter your question here."
                required
            ></v-text-field>
        </v-col>
        <v-col cols="6">
            <v-select
                dense
                multiple
                v-model="internalData.category"
                :items="categories"
                label="Question Category"
                item-text="text"
                item-value="value"
                required
            ></v-select>
        </v-col>
        <v-col cols="3">
            <v-text-field
                v-model="internalData.limit"
                type="number"
                class="field"
                dense
                filled
                solo
                flat
                label="Limit"
                placeholder="200"
                :value="200"
            ></v-text-field>
        </v-col>
        <v-col cols="3">
            <v-checkbox v-model="internalData.required" class="pa-auto ma-auto" label="Required"></v-checkbox>
        </v-col>
        <v-col cols="12">
            <v-divider color="grey"></v-divider>
        </v-col>
    </v-row>
</template>
<script>
export default {
    props: {
        categories: {
            type: Array,
            default: () => ({}),
        },
        value: {
            type: Object,
            default: () => ({}),
        },
    },
    data() {
        return {
            internalData: this.value,
        };
    },
    watch: {
        internalData: {
            handler(newVal) {
                console.log(newVal);
                this.$emit("input", newVal);
            },
            deep: true,
        },
        value(newVal) {
            this.internalData = newVal;
        },
    },
};
</script>
