import FluroContent from "./fluro-content";

class Bundles extends FluroContent {
    constructor() {
        super("bundle");
    }
}

const _Bundles = new Bundles();
export default _Bundles;
